.contactmap {
	width: 100%;

	@include media-breakpoint-down(xl) {
		min-height: 510px;
	}

	@include media-breakpoint-down(sm) {
		min-height: 300px;
	}
}
