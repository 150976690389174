@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-Regular.eot');
	src: url('../fonts/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PTSans-Regular.woff2') format('woff2'),
		url('../fonts/PTSans-Regular.woff') format('woff'),
		url('../fonts/PTSans-Regular.ttf') format('truetype'),
		url('../fonts/PTSans-Regular.svg#PTSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-Bold.eot');
	src: url('../fonts/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PTSans-Bold.woff2') format('woff2'),
		url('../fonts/PTSans-Bold.woff') format('woff'),
		url('../fonts/PTSans-Bold.ttf') format('truetype'),
		url('../fonts/PTSans-Bold.svg#PTSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-Italic.eot');
	src: url('../fonts/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PTSans-Italic.woff2') format('woff2'),
		url('../fonts/PTSans-Italic.woff') format('woff'),
		url('../fonts/PTSans-Italic.ttf') format('truetype'),
		url('../fonts/PTSans-Italic.svg#PTSans-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-BoldItalic.eot');
	src: url('../fonts/PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PTSans-BoldItalic.woff2') format('woff2'),
		url('../fonts/PTSans-BoldItalic.woff') format('woff'),
		url('../fonts/PTSans-BoldItalic.ttf') format('truetype'),
		url('../fonts/PTSans-BoldItalic.svg#PTSans-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: "slick";
        src: slick-font-url("../fonts/slick.eot");
        src: slick-font-url("../fonts/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("../fonts/slick.woff") format("woff"), slick-font-url("../fonts/slick.ttf") format("truetype"), slick-font-url("../fonts/slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
}
