.breadcrumbs {
	padding: 0px 0 10px;

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&__item {
		display: inline-block;
		position: relative;
		padding-right: 5px;

		&:after {
			content: '\203a';
			display: inline-block;
			// position: absolute;
			left: 0px;
			top: 0px;
			// color: $color-red;
			margin-left: 7px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__link,
	&__current {
		text-decoration: none;
		// color: $color-red;
		font-size: 15px;
	}

	&__link {
		&:hover {
			text-decoration: underline;
		}
	}
}
