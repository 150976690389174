.mod-actions {
	position: relative;
	margin-top: 30px;
}
.countdownHolder{
	/*margin:0 auto;*/
	font: 25px/1.5 'Open Sans Condensed',sans-serif;
	letter-spacing:-3px;
	margin-bottom: 10px;
	// text-align:center;
	// width:450px;
	// position: absolute;
	// bottom: 0;
	// right: 0;

	@include media-breakpoint-only(xl) {
		// min-height: 1210px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 18px;
	}
}
.position{
	display: inline-block;
	height: 1.6em;
	overflow: hidden;
	position: relative;
	width: 1.05em;
}
.digit{
	position:absolute;
	display:block;
	width:1em;
	background-color:#444;
	border-radius:0.2em;
	text-align:center;
	color:#fff;
	letter-spacing:-1px;
}
.digit.static{
	box-shadow:1px 1px 1px rgba(4, 4, 4, 0.35);

	background-image: linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -o-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -moz-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -webkit-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -ms-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);

	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0.5, #3A3A3A),
		color-stop(0.5, #444444)
	);
}

/**
 * You can use these classes to hide parts
 * of the countdown that you don't need.
 */

.countDays, .countHours, .countMinutes, .countSeconds{
/* display:none !important;*/
	display: inline-block;
	border-radius:0.2em;
	background-color:#999;
	padding: 3px;
}
.countDays:after, .countHours:after, .countMinutes:after, .countSeconds:after{
/* display:none !important;*/
	content: '';
	display: block;
	// width: 60%;
	text-align: center;
	margin: -8px auto 3px;
	border-radius:0.2em;
	box-shadow:1px 1px 1px rgba(4, 4, 4, 0.35);
	font-size: 10px;
	color: white;
	line-height: 12px;
	letter-spacing:1px;
	background-image: linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -o-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -moz-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -webkit-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -ms-linear-gradient(bottom, #3A3A3A 50%, #444444 50%);
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.5, #3A3A3A), color-stop(0.5, #444444));
}
.countDays:after {
	content: "Дней";
}
.countHours:after {
	content: "Часов";
}
.countMinutes:after {
	content: "Минут";
}
.countSeconds:after {
	content: "Секунд";
}
.countDiv0{ /* display:none !important;*/ }
.countHours{}
.countDiv1{}
.countMinutes{}
.countDiv2{}
.countSeconds{}


.countDiv{
	display:inline-block;
	width:16px;
	height:1.6em;
	position:relative;
}

.countDiv:before,
.countDiv:after{
	position:absolute;
	width:5px;
	height:5px;
	background-color:#444;
	border-radius:50%;
	left:50%;
	margin-left:-3px;
	top:0.5em;
	box-shadow:1px 1px 1px rgba(4, 4, 4, 0.5);
	content:'';
}

.countDiv:after{
	top:0.9em;
}
.countdown {

	@media (min-width: 535px) and (max-width: 576px) {
		width: 300px;
		float: left;
		padding-right: 0;
	}

	@include media-breakpoint-only(xs) {
		width: 270px;
		float: left;
		padding-right: 0;
	}

	@include media-breakpoint-only(sm) {
		// width: 47%;
		// float: left;
		padding: 0;
	}

	@include media-breakpoint-only(md) {
		// width: 47%;
		// // padding-left: 0;
		// float: left;
	}

	&__off {
		cursor: pointer;

		&--off {
			color: red;
		}

		&--on {
			color: green;
		}
	}

	&--hidden {
		display: none;
	}
}
.countdown-text {

	a {
		font-size: 22px;
		font-weight: bold;
		text-transform: uppercase;
		color: $color-blue;

		@include media-breakpoint-down(md) {
			font-size: 16px;
		}

		&:hover {
			text-decoration: none;
			color: $color-blue-2;
		}
	}
}
