 $k: 0;
 @while $k < 30 {
 	.u-radius-#{$k} {
 		border-radius: #{$k}px;
     }
    .u-radius-tl-#{$k} {
 		border-top-left-radius: #{$k}px;
     }
    .u-radius-tr-#{$k} {
 		border-top-right-radius: #{$k}px;
     }
    .u-radius-bl-#{$k} {
 		border-bottom-left-radius: #{$k}px;
     }
    .u-radius-tr-#{$k} {
 		border-bottom-right-radius: #{$k}px;
     }
 	$k: $k + 1;
 }

 .u-radius-round {
     border-radius: 50%;
 }
