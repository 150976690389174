.shipping {
	color: white;
	padding-top: 15px;
	padding-bottom: 65px;

	.heading {
		color: $color-blue-2;
		margin-bottom: 7px;
	}

	.description {
		font-size: 25px;
		margin-bottom: 48px;
	}

	&__text-heading {
		margin-bottom: 35px;
		padding-left: 50px;
		position: relative;
	}

	&__text {
		position: relative;

		&--1 {

			.shipping__text-heading {
				&::before {
					content: '1';
					display: inline-block;
					position: absolute;
					width: 32px;
					height: 32px;
					background: $color-blue-2;
					border-radius: 50%;
					text-align: center;
					left: 0;
					top: 10px;
					font-size: 27px;
					line-height: 30px;
					font-weight: bold;
					color: $color-blue-3;
				}
			}
		}

		&--2 {



			@include media-breakpoint-only(xl) {
				&::before {
					content: '';
					display: block;
					position: absolute;
					left: -60px;
					top: 0;
					height: 100%;
					width: 2px;
					background: $color-blue-2;
				}
			}

			.shipping__text-heading {
				&::before {
					content: '2';
					display: inline-block;
					position: absolute;
					width: 32px;
					height: 32px;
					background: $color-blue-2;
					border-radius: 50%;
					text-align: center;
					left: 0;
					top: 10px;
					font-size: 27px;
					line-height: 30px;
					font-weight: bold;
					color: $color-blue-3;
				}
			}
		}
	}

	.card {
		margin-bottom: 45px;

		&:last-child {
			margin: 0;
		}

		img {
			max-width: 100%;
		}

		&__name {
			font-size: 21px;
			line-height: 27px;

			a {
				color: white;
			}
		}

		&__desc {
			color: $color-blue-1;
		}
	}
}
