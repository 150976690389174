.site-name {
  width: 100%;
  text-align: center;
  padding-bottom: 15px;

  @include media-breakpoint-only(sm) {
    width: 50%;
    float: left;
    text-align: left;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    text-align: left;
  }

  &__top {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 10px;
    color: $color-blue;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 24px;
      padding-bottom: 0px;
    }
  }

  &__bottom {
    margin-bottom: 10px;
  }

  &__content {
    text-align: right;

    @include media-breakpoint-down(md) {
      display: inline-block;
    }
  }

  &__firm,
  &__name {
    display: inline-block;
    color: $color-base;
  }

  &__firm {
    font-size: 15px;
    text-align: right;
    color: $color-gray-4;
  }

  &__name {
    font-size: 57px;
    line-height: 47px;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 30px;
    }
  }
}

.footer {

  .site-name {
    margin-bottom: 20px;

    a {
      color: $color-gray;
    }

    &__top {
      color: white;
      font-size: 30px;
      padding-top: 0;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        padding-bottom: 0px;
      }
    }

    &__bottom {
      color: $color-gray-5;
    }

    &__firm,
    &__name {
      color: $color-gray;
    }

    &__firm {

    }

    &__name {
      font-size: 48px;

      @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 30px;
      }
    }
  }
}
