.menu {

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		padding: 21px 0;
	}

	&__item {
		margin: 0 20px;
	}

	&__link {
		display: inline-block;
		text-decoration: none;
		text-transform: uppercase;
		padding: 6px 0;
	}

	&--horizontal {
		& > .menu__list {

			& > .menu__item {
				display: inline-block;
			}
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&--dropdown {
		.menu__item--parent {
			position: relative;

			& > .menu__list {
				display: none;
				position: absolute;
			}

			&:hover > .menu__list {
				display: block;
				z-index: 2;
			}
		}
	}
}

.topmenu {
	.menu__link {
		color: white;
		border-top: 2px solid $color-blue;
		border-bottom: 2px solid $color-blue;

		&:hover {
			border-top: 2px solid $color-blue-2;
			border-bottom: 2px solid $color-blue-2;
		}
	}
}

.bootmenu {
	.menu__link {
		color: white;
		border-top: 2px solid $color-dark;
		border-bottom: 2px solid $color-dark;

		&:hover {
			border-top: 2px solid $color-gray-4;
			border-bottom: 2px solid $color-gray-4;
		}
	}
}
