 $t: 0;
 @while $t < 155 {
 	.u-pt-#{$t} {
 		padding-top: #{$t}px;
 	}
 	$t: $t + 5;
 }

 $b: 0;
 @while $b < 155 {
 	.u-pb-#{$b} {
 		padding-bottom: #{$b}px;
 	}
 	$b: $b + 5;
 }

 $l: 0;
 @while $l < 155 {
 	.u-pl-#{$l} {
 		padding-left: #{$l}px;
 	}
 	$l: $l + 5;
 }

 $r: 0;
 @while $r < 155 {
 	.u-pr-#{$r} {
 		padding-right: #{$r}px;
 	}
 	$r: $r + 5;
 }

 $a: 0;
 @while $a < 155 {
 	.u-p-#{$a} {
 		padding: #{$a}px;
 	}
 	$a: $a + 5;
 }

 .u-p-0 {
	 padding: 0;
 }