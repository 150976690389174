.go-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	opacity: .4;

	@include media-breakpoint-down(md) {
		display: none;
	}
}
