.assortment {
	padding: 35px 0;

	@include media-breakpoint-only(xs) {
		padding: 35px 15px;
	}

	.heading {
		margin-bottom: 15px;
	}

	.description {
		margin-bottom: 30px;
	}

	.grid {

		&__item {
			margin-bottom: 30px;
		}

		.row {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}
	}


	.card {
		position: relative;

		&__img {

			img {
				width: 100%;
			}
		}

		&__name {
			font-size: 21px;
			line-height: 27px;
			margin-bottom: 17px;

			@include media-breakpoint-down(md) {
				margin-bottom: 5px;
				font-size: 18px;
			}
		}

		&__price {
			font-size: 31px;
			color: $color-blue;
			font-weight: bold;
			margin-bottom: 7px;

			@include media-breakpoint-down(md) {
				font-size: 24px;
				margin-bottom: 5px;
			}
		}

		&__ps {
			font-size: 15px;
			line-height: 27px;
			color: $color-gray-6;

			@include media-breakpoint-down(md) {
				line-height: 17px;
			}
		}

		&__button {
			position: relative;
			padding: 0 15px;
			margin: 10px 0;
			background: white;
			// display: inline-block;
			// bottom: 18px;
			// left: 50%;
			// margin-left: -80px;

			// @include media-breakpoint-up(md) {
			// 	margin-left: -90px;
			// 	bottom: 24px;
			// }

			// @include media-breakpoint-up(xl) {
			// 	margin-left: -95px;
			// 	bottom: 25px;
			// }
		}
	}

	&__wrap {
		border: 2px solid $color-gray-3;
		padding: 20px 15px 0;
		text-align: center;
		height: 100%;
		position: relative;
	}

}
