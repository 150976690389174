.action {

	.heading {
		color: $color-base;
		margin-bottom: 18px;
	}

	.description {
		color: $color-gray-5;
		font-size: 25px;
		margin-bottom: 40px;
	}

	.grid {

		&__item {
			margin-bottom: 60px;
			// padding-top: 160px;
		}
	}

	.card {
		text-align: center;

		&__img {
			padding-bottom: 10px;

			img {
				max-width: 100%;
			}
		}

		&__name,
		&__price {
			// color: white;
			padding-bottom: 10px;
		}

		&__old_price {
			color: $color-gray-5;
		}
	}

}
