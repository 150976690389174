// Colors
$color-gray: 	#e6e6e6;
$color-gray-2: 	#b3b3b3;
$color-gray-3: 	#ccc;
$color-gray-4: 	#666;
$color-gray-5: 	#999;
$color-gray-6: 	#808080;
$color-blue: 	#369;
$color-blue-1: 	#7aa8d6;
$color-blue-2: 	#5085ba;
$color-blue-3: 	#27598c;
$color-blue-4: 	#b7dbff;
$color-dark: 	#333;

$color-gray-a: rgba(230, 230, 230, .9);
$color-dark-a: 	rgba(0,0,0,.9);
$color-white-a: 	rgba(255,255,255,.75);

$color-base: 	$color-dark;

//Font
$font-ptsans: 'PT Sans', 'Helvetica', sans-serif;
$font-base: $font-ptsans;
