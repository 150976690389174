.u-alert-e {
	color: red;
}

.u-alert-s {
	color: green;
}

.u-alert-w {
	color: orange;
}
