/**
 * Basic typography style for copy text
 */

body {
	color: $color-base;
	font-family: $font-base;
	font-size: 20px;
	line-height: 24px;
}

h1, h2, h3, h4, h5, h6 {
	// font-family: PlumbcBold, Plumbc, sans-serif;
}

p {
	margin: 0 0 30px 0;
}

a,
a:hover,
a:active {
	color: $color-blue-2;
	outline: none;
}
