.charakteristiks {
    padding-bottom: 30px;
    
    &__row {
        margin-bottom: 10px;
        padding: 0 15px;
    }

    &__title {
        border-bottom: 1px dotted black;
        padding: 0;
    }
    
    &__value {
        border-bottom: 1px dotted black;
        padding: 0;
    }
}