.phonemenu-button {
	padding-top: 10px;
	text-align: center;
	width: 100%;

	//@include media-breakpoint-only(xs) {
	//	width: 40px;
	//	float: left;
	//	padding-left: 0;
	//}

	// @media (min-width: 20px) and (max-width: 260px) {
	// 	width: 100%;
	// 	text-align: center;
	// }

	// @media (min-width: 260px) and (max-width: 350px) {
	// 	padding-top: 10px;
	// }

	@include media-breakpoint-only(sm) {
		padding-left: 0;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&__text {
		font-size: 30px;
		cursor: pointer;
		display: inline-block;

		@media (min-width: 576px) {
			font-size: 50px;
		}
	}

	&__span {
		font-size: 25px;
		text-transform: uppercase;

		@media (min-width: 576px) {
			display: none;
		}
	}
}

.phonemenu {
	color: white;

	&__wrap {
		display: none;
		height: 100%;
		overflow: auto;
		padding: 30px 15px;

		&--active {
			display: block;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 165;
		}
	}

	&__contacts {
		font-size: 14px;
		padding-right: 30px;
		min-width: 20px;
		max-width: 410px;
		margin: 0 auto;
	}

	&__contacts-info {
		position: relative;
			padding-left: 25px;
			margin-bottom: 22px;

			&:last-child {
				margin: 0;
			}
		}

	&__contacts-icon {
		position: absolute;
		display: inline-block;
		height: 15px;
		left: 0;

		&--point {
			background: url('../img/sprite.png') no-repeat -55px -73px;
			width: 15px;
			top: 3px;
		}

		&--phone {
			background: url('../img/sprite.png') no-repeat -30px  -73px;
			width: 15px;
			top: 4px;
		}

		&--mail {
			background: url('../img/sprite.png') no-repeat 0px  -73px;
			width: 15px;
			top: 8px;
		}
	}

	.menu {

		&__heading {
			text-transform: uppercase;
			border-bottom: 1px solid white;
			margin-bottom: 10px;
			padding-bottom: 10px;
			text-align: center;
		}

		&--main {
			margin-top: 15px;
			padding-top: 15px;
		}

		&__list {
		}

		&__link {
			color: white;
			padding: 5px 0px;
			line-height: 20px;
			width: 100%;
		}

		&__item {
			margin-bottom: 1px;

			&.active {
				& > .menu__link {
					color: red;
				}
			}
		}
	}

	&__close {
		position: fixed;
		top: 18px;
		right: 23px;
		width: 29px;
		height: 29px;
		color: white;
		background: none;
		font-size: 40px;
		padding: 0;
		z-index: 15;

		&:after,
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 34px;
			height: 3px;
			background: white;
			top: 12px;
			right: -4px;
		}

		&:after {
			transform: rotate(45deg);
			-o-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
		}

		&:before {
			transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
		}

		&:hover {
			cursor: pointer;

			&:after,
			&:before {
				cursor: pointer;
			}
		}
	}
}
