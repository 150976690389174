.pagination {
    width: 100%;
    text-align: center;

    &__list {
        margin: 0;
        padding: 0;
        text-align: center;
        list-style: none;
    }

    &__item {
        display: inline-block;
        margin: 0 3px;

        &--next,
        &--prev {
            .pagination__link {
                // border: 1px solid black;
                // background: black;
                font-size: 12px;
                
                &:hover {
                    background: none;

                    .pagination__icon {
                        color: $color-blue-2;
                    }
                }
            }

            .pagination__icon {
                color: white;
                position: relative;
                top: 2px;
            }
        }

        &--current {
            a {
                text-decoration: none;
                display: inline-block;
                vertical-align: middle;
                padding: 4px 12px;
                border: 1px solid $color-blue-2;
                background: $color-blue-2;
                color: white;
            }
        }
    }

    &__link {
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        padding: 4px 10px;
        border: 1px solid transparent;
        
        &--active,
        &:hover,
        &.tab-control--active {
            border: 1px solid $color-blue-2;
        }
        
        &.tab-control--active {
            background: $color-blue-2;
            color: white;
        }
    }
}