.section {
	@include clearfix;

	&--header {
		background: $color-gray;

		@include media-breakpoint-up(lg) {
			padding-top: 16px;
			padding-bottom: 21px;
		}

		.container {
			@include media-breakpoint-up(lg) {
				background: url('../img/gramo.png') no-repeat 100% 50%;
				background-size: contain;
			}
		}
	}

	&--topmenu {
		background: $color-blue;
	}

	&--about {
		background: white;
		padding: 35px 0 0 0;
	}

	&--action {
		background: black;
		padding-top: 22px;

		@include media-breakpoint-up(lg) {
			background: $color-gray;
		}
	}

	&--shipping {
		background: $color-blue-3 url("../img/bg-4.png") no-repeat center top;
	}

	&--contact-form {
		background: $color-gray;
		border-bottom: 8px solid $color-gray-4;

		@include media-breakpoint-up(lg) {
			background: white;
		}
	}

	&--bootmenu {
		background: $color-dark;
		border-bottom: 1px solid white;
	}

	&--footer {
		background: $color-dark;
	}
}
