.contacts {

	@include media-breakpoint-down(md) {
		display: none;
	}

	&__info {
		position: relative;
		padding-left: 30px;
		margin-bottom: 22px;

		&:last-child {
			margin: 0;
		}

		@include media-breakpoint-down(lg) {
			font-size: 16px;
			margin-bottom: 10px;
		}

		@include media-breakpoint-down(md) {
			font-size: 14px;
		}
	}

	.fa {
		position: absolute;
		left: 0;
	}
}

.footer {

	.contacts {

		@include media-breakpoint-down(md) {
			display: block;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;;
		}

		&__info {
			padding-left: 30px;
			color: $color-gray;
			font-size: 16px;

			@include media-breakpoint-down(lg) {
				font-size: 14px;
			}
		}
	}

}
