.madein {
	align-self: flex-end;
	color: $color-gray;
	font-size: 14px;
	text-align: right;

	@include media-breakpoint-down(sm) {
		text-align: left;
	}

	&__link {
		font-size: 17px;
		color: white;

		&:hover,
		&:active,
		&:visited {
			color: white;
		}

		&:hover {
			text-decoration: none;
		}
	}
}
