.header {
	// background: $color-gray-a;

	@include media-breakpoint-down(md) {
		position: fixed;
		background: $color-gray-a;
		border-bottom: 2px solid $color-blue;
		padding: 5px 15px;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 10;
	}
}
