.form {

	&__input,
	&__textarea {
		font-size: 20px;
		border: 1px solid $color-gray-3;
		width: 100%;
		padding: 12px 16px 10px;
		background: white;
		margin-bottom: 15px;
	}

	&__textarea {
		min-height: 150px;
		margin-bottom: 30px;
	}

	&__button-block  {
		text-align: center;
	}
}
