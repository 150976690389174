.catalog {
	.assortment {
		padding: 0;

		.grid {

			&__item {

			}
		}
	}

	&--root {

	}

	&__link {
		// text-decoration: none;
		color: inherit;

		&:active,
		&:visited {
			color: inherit;
		}

		&:hover {
			color: $color-blue-2;
			text-decoration: none;
		}
	}

	&__category {
		margin-bottom: 30px;
	}

	.readmore {
		text-align: center;

		.button {
			color: white;
		}
	}

	&__product-desc {
		margin-top: 30px;
	}

	&__product-price {
		font-size: 31px;
		color: $color-blue;
		font-weight: bold;
		margin-bottom: 30px;

		span {
			font-size: 20px;
			color: $color-base;
		}

		@include media-breakpoint-down(md) {
			font-size: 24px;
			margin-bottom: 5px;

			span {
				font-size: 16px;
				color: $color-base;
			}
		}
	}
}
