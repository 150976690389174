.carousel {
	position: relative;
	padding-top: 140px;

	@media (max-width: 576px) {
		padding-top: 270px;
	}

	@media (min-width: 991px) {
		padding-top: 0;
	}

	&__image {
		width: 100%;
	}

	&__content {
		position: absolute;
		top: 0;
		left: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 1200px;
		margin-left: -600px;

		@include media-breakpoint-down(lg) {
			width: 768px;
			margin-left: -384px;
		}

		@include media-breakpoint-down(sm) {
			width: 500px;
			margin-left: -250px;
		}

		@include media-breakpoint-down(xs) {
			width: 100%;
			margin-left: 0px;
			left: 0;
			padding: 0 15px;
		}
	}

	.heading {
		font-size: 46px;
		color: white;
		text-align: center;
		margin-top: 6%;

		@include media-breakpoint-down(lg) {
			font-size: 30px;
			margin-top: 2%;
		}

		@include media-breakpoint-down(xs) {
			font-size: 20px;
			margin-top: 10px;
			margin-bottom: 15px;
		}
	}

	&__desc {
		font-size: 25px;
		color: white;
		text-align: center;
		text-transform: uppercase;
		position: relative;

		@include media-breakpoint-down(xl) {
			margin-bottom: 60px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 18px;
			margin-bottom: 0px;
		}

		@include media-breakpoint-down(xs) {
			margin-bottom: 15px;
		}

		&::after,
		&::before {
			content: '';
			display: inline-block;
			height: 2px;
			width: 100px;
			background: white;
			position: relative;
			top: -7px;

			@include media-breakpoint-down(xs) {
				top: -1px;
				display: block;
				margin: 5px auto;
			}
		}
	}

	&__desc-text {
		margin: 0 20px 20px;
		display: inline-block;

		@include media-breakpoint-only(xl) {
		}

		@include media-breakpoint-down(xs) {
			margin: 0 20px 0px;
		}
	}

	.buttons-block {
		text-align: center;
	}

	.slick-prev,
	.slick-next  {
		z-index: 5;
		width: 41px;
		height: 61px;

		&::before {
			content: "";
			background: url('../img/sprite.png') -81px 0 no-repeat;
			width: 41px;
			height: 61px;
			display: inline-block;

		}
	}

	.slick-prev {
		left: 15px;

		@include media-breakpoint-only(xl) {
			left: 50%;
			margin-left: -568px;
		}

		&::before {
			background-position: -122px 0;
		}
	}

	.slick-next {
		right: 15px;

		@include media-breakpoint-only(xl) {
			right: 50%;
			margin-right: -568px;
		}
	}
}
