.about {
	padding-bottom: 30px;

	.heading {
		margin-bottom: 35px;
	}

	.description {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;

		&__wrap {

			@include media-breakpoint-only(lg) {
				padding: 15px;
				background: $color-white-a;
				height: 100%;
			}
		}
	}
}
