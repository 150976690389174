.button {
	display: inline-block;
	background: $color-blue;
	border: none;
	color: white;
	font-size: 20px;
	line-height: 1;
	text-transform: uppercase;
	padding: 15px 26px 12px;
	outline: none;
	text-decoration: none;

	&:hover {
		background: $color-blue-2;
		color: white;
	}

	@include media-breakpoint-down(lg) {
		font-size: 18px;
		padding: 15px 26px 13px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 16px;
		padding: 10px 20px;
	}
}
