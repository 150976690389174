html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

.cover {
	display: none;

	&--active {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:  $color-dark-a;
		z-index: 160;
	}
}

.image {
	max-width: 100%;
}

.clearfix {
	&::before, &::after {
		content: '';
		display: table;
		clear: both;
	}
}

//developers responsive markers
.dev {
	position: relative;
	@include media-breakpoint-only(xs) {
		&:before {
			content: "xs: 0 >";
			color: white;
			text-shadow: 0 0 5px black;
			position: fixed;
			top: 2%;
			left: 5%;
			font-size: 30px;
			font-weight: 700;
			z-index: 1000;
		}
	}
	@include media-breakpoint-only(sm) {
		&:before {
			content: "sm: 576px";
			color: white;
			text-shadow: 0 0 5px black;
			position: fixed;
			top: 2%;
			left: 5%;
			font-size: 30px;
			font-weight: 700;
			z-index: 1000;
		}
	}
	@include media-breakpoint-only(md) {
		&:before {
			content: "md: 768px";
			color: white;
			text-shadow: 0 0 5px black;
			position: fixed;
			top: 2%;
			left: 5%;
			font-size: 30px;
			font-weight: 700;
			z-index: 1000;
		}
	}
	@include media-breakpoint-only(lg) {
		&:before {
			content: "lg: 992px";
			color: white;
			text-shadow: 0 0 5px black;
			position: fixed;
			top: 2%;
			left: 5%;
			font-size: 30px;
			font-weight: 700;
			z-index: 1000;
		}
	}
	@include media-breakpoint-only(xl) {
		&:before {
			content: "xl: 1200px";
			color: white;
			text-shadow: 0 0 5px black;
			position: fixed;
			top: 2%;
			left: 5%;
			font-size: 30px;
			font-weight: 700;
			z-index: 1000;
		}
	}
}
