.gallery {

	&__thumb {
		margin-bottom: 15px;
	}

	&__image {
		max-width: 100%;
	}
}
