.contact-form {
	padding-top: 20px;
	padding-bottom: 38px;

	.heading {
		margin-bottom: 44px;
	}

	.description {
		font-size: 21px;
		line-height: 27px;
		margin-bottom: 35px;
	}
}
