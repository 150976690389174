.heading,
h1.heading {
	font-size: 30px;
	line-height: 1.2;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 0;
	color: $color-gray-2;
	margin-bottom: 30px;
	margin-top: 0;

	@include media-breakpoint-up(lg) {
		font-size: 40px;
	}

	&--h2 {
		font-size: 20px;

		@include media-breakpoint-up(lg) {
			font-size: 30px;
		}
	}

	&--h3 {
		font-size: 16px;
		color: $color-base;

		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}
}
