.item {

	&__img {

		@include media-breakpoint-up(lg) {
			display: inline-block;
			float: left;
			margin: 0 15px 5px 0;
		}

		img {
			max-width: 100%;
		}
	}
}
