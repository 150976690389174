.search {
  &__input {
    width: calc(100% - 50px);
  }

  &__submit {
    width: 36px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__link {
    display: inline-block;
    line-height: 50px;
    vertical-align: middle;
  }
}